import React from "react"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import { MetaData } from "../../components/meta"
import Layout from "../../components/layout/defaultlayout"
import { PhotoPortfolio } from "../../components/common/sections"

/**
 * GraphQL Query used to obtain post data from Ghost CMS
 * 
 * This GraphQL query uses Template Literals (allowing embedded expressions called substitutions)
 * The query uses slug because data is passed from gatsby-node.js, which sends each post (data), to be used by this React component. The slug is found in the context field of createPage()
 * Anything within context is made avialiable as a variable to GraphQL, which is why we could use fields within context such as the slug field.
 * @description Gets post based on slug (name of post)
 * @example https://egghead.io/lessons/gatsby-create-pages-in-gatsby-using-graphql
 * @field GhostPostFields: A GraphQL fragment defined in fragments.js for fetching fields in post
 */
export const postQuery = graphql`
    query ($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
    }
`
/**
 * @description Creates post from Ghost CMS data on slug (name of post)
 * @example https://egghead.io/lessons/gatsby-create-pages-in-gatsby-using-graphql
 * @var data: Anyying that comes out GraphQL query below will be retured in variable object.
 * @var location:
 */
const Post = ({ data, location }) => {
    let feature_image, photos, articleClassName

    const GoogleAdsInArticleAd = `
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3464720159577385"
        crossorigin="anonymous"></script>
    <ins class="adsbygoogle"
        style="display:block; text-align:center;"
        data-ad-layout="in-article"
        data-ad-format="fluid"
        data-ad-client="ca-pub-3464720159577385"
        data-ad-slot="3868743340"></ins>
    <script>
        (adsbygoogle = window.adsbygoogle || []).push({});
    </script>
    `
    const post = data.ghostPost
    feature_image = post.feature_image

    /**
     * Assign classname value to variable articleClassName
     *
     * This variable will used on a <article/> HTML element. The reason for this conditiona is the diferentiate sheetstyle between a regular post and photography post
     * @var articleClassName: Classname attribute for articule containing
     */
    photos = eval(post.codeinjection_head)
    if (photos) {
        articleClassName = "content.photography"
    } else {
        articleClassName = "content"
    }

    return (
        <>
            <MetaData data={data} location={location} type="article" />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
                <style type="application/javascript">{`${GoogleAdsInArticleAd}`}</style>
            </Helmet>
            <Layout>
                <div id="post" className="container">
                    <article className={articleClassName}>
                        <Link
                            className="ac"
                            to={"/blog/author/" + post.primary_author.slug}
                        >
                            <div className="post-author-header">
                                <img
                                    className="post-author-image"
                                    src={post.primary_author.profile_image}
                                    alt={
                                        post.primary_author.name +
                                        "Profile Image"
                                    }
                                />
                                <h6>{post.primary_author.name}</h6>
                            </div>
                        </Link>
                        <section className="intro-content">
                            <h1 className="content-title">{post.title}</h1>
                            {feature_image ? (
                                <figure className="post-feature-image">
                                    <img src={feature_image} alt={post.title} />
                                </figure>
                            ) : null}
                        </section>
                        <br />
                        <section className="post-full-content">
                            <section
                                className="content-body load-external-scripts"
                                dangerouslySetInnerHTML={{ __html: post.html }}
                            />
                        </section>
                        <section>
                            {post.codeinjection_head && (
                                <PhotoPortfolio photos={photos} />
                            )}
                        </section>
                    </article>
                </div>
            </Layout>
        </>
    )
}

export default Post
